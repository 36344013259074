<template>
  <div class="dealOrder">
    <div class="dealOrderTitle">
      <div class="myDealOrder">
        <p>产品预售协议</p>
      </div>
    </div>
    <el-table :data="tableData" border>
      <el-table-column prop="code" label="协议号"></el-table-column>
      <el-table-column prop="name" label="商品"></el-table-column>
      <el-table-column prop="quantity" label="数量"></el-table-column>
      <el-table-column prop="price" label="价格"></el-table-column>
      <el-table-column prop="buyInfo" label="买家信息"></el-table-column>
      <el-table-column prop="createTime" label="签约时间"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="applyPickGoods(scope.row)">确定协议</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      tableData: [
        // {
        //   code: "clw009999",
        //   name: "钢材",
        //   quantity: 2,
        //   price: "300.00 RMB",
        //   buyInfo: "买家信息",
        //   createTime: "2020-03-06"
        // },
        // {
        //   code: "clw009999",
        //   name: "钢材",
        //   quantity: 2,
        //   price: "300.00 RMB",
        //   buyInfo: "买家信息",
        //   createTime: "2020-03-06"
        // },
        // {
        //   code: "clw009999",
        //   name: "钢材",
        //   quantity: 2,
        //   price: "300.00 RMB",
        //   buyInfo: "买家信息",
        //   createTime: "2020-03-06"
        // },
        // {
        //   code: "clw009999",
        //   name: "钢材",
        //   quantity: 2,
        //   price: "300.00 RMB",
        //   buyInfo: "买家信息",
        //   createTime: "2020-03-06"
        // },
        // {
        //   code: "clw009999",
        //   name: "钢材",
        //   quantity: 2,
        //   price: "300.00 RMB",
        //   buyInfo: "买家信息",
        //   createTime: "2020-03-06"
        // }
      ],
      current: 1,
      pageSize: 10,
      totalPage: null
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    // this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryBuyerAgreement.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_queryBuyerAgreement.param.page = this.current - 1;
      protocolFwd.param_queryBuyerAgreement.param.size = this.pageSize;
      http.postFront(protocolFwd.param_queryBuyerAgreement).then(response => {
        const datas = response.data.content;
        for (let i = 0; i < datas.length; i++) {
          if (datas[i].bsFlag == "B") {
            datas[i].bsFlagName = "买入";
          } else if (datas[i].bsFlag == "S") {
            datas[i].bsFlagName = "卖出";
          }
          if (datas[i].createTime) {
            datas[i].createTimeFormat = global.util.DateFormate(
              datas[i].createTime
            );
          } else {
            datas[i].createTimeFormat = "--";
          }
        }
        this.tableData = response.data.content;
        this.totalPage = response.data.totalElements;
      });
    },
    applyPickGoods(item) {
      this.$confirm("确认协议?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$EL_MESSAGE("协议确认成功");
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.dealOrder {
  padding: 0 15px;
}
.dealOrderTitle {
  overflow: hidden;
}
.dealOrder .myDealOrder {
  float: left;
  line-height: 40px;
}
.dealOrderTitle .myDealOrder p {
  font-size: 16px;
}
.orderDetails {
  line-height: 35px;
}
.orderDetails .listTitle {
  display: block;
  text-align: right;
}
.orderDetails .listContent {
  margin-left: 5px;
}
.dealOrder .pagination {
  margin-top: 15px;
}
</style>
